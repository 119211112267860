import Q from "q";
import _ from "underscore";
import LoginCore from "yinzhilv-js-sdk/frontend/common-project/component/haolv/login-core/1.0.0/index.vue";
import Footer from "@/component/back-layout/1.1.5/component/footer/1.0.1/index.vue";
import DownlodApp from "@/component/download-app/index.vue";

export default {
    components: {
        LoginCore,
        DownlodApp,
        Footer,
    },
    data() {
        return {
            activeName: 'homePage',
            typeData: [
                {
                    typeName: '企业差旅',
                    typeTitle: '一体化商旅解决方案'
                }
            ],
            typeDataAll: [
                {
                    typeName: '企业差旅',
                    typeTitle: '一体化商旅解决方案'
                },
                {
                    typeName: '商旅合作',
                    typeTitle: '如果您希望进一步了解商务旅行服务的相关信息，请联系我们，我们资深的客户经理将为您提供专业、细致的服务。'
                },
                {
                    typeName: '服务案例部分展示',
                    typeTitle: '为数百家知名企业及政府单位提供差旅服务'
                },
                {
                    typeName: '关于我们',
                    typeTitle: '铨成商旅——出差商洽，全都能成！'
                }
            ]
        }

    },
    methods: {
        ImgMouseover(num) {
            this.setTime = setTimeout(() => {
                this.$refs.carousel.setActiveItem(num);
            },300);
        },
        ImgMouseout(){
            clearTimeout(this.setTime);
        },
        handleClick(tab, event){
            if (tab.$parent.currentName == 'homePage') {
                this.$router.push({
                    name:'login',
                });
            } else {
                this.$router.push({
                    name:'login-tabel',
                    query:{
                        type: tab.$parent.currentName,
                    }
                });
            }
            switch (this.activeName) {
                case 'companyTravel':
                    this.typeData = this.typeDataAll[0];
                    break;
                case 'cooperation':
                    this.typeData = this.typeDataAll[1];
                    break;
                case 'serviceCase':
                    this.typeData = this.typeDataAll[2];
                    break;
                case 'aboutWe':
                    this.typeData = this.typeDataAll[3];
                    break;
            }
        },
    },
    mounted() {
        this.activeName = this.$route.query.type;
        switch (this.activeName) {
            case 'companyTravel':
                this.typeData = this.typeDataAll[0];
                break;
            case 'cooperation':
                this.typeData = this.typeDataAll[1];
                break;
            case 'serviceCase':
                this.typeData = this.typeDataAll[2];
                break;
            case 'aboutWe':
                this.typeData = this.typeDataAll[3];
                break;
        }

    },
    activated() {

    },
};
